import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import Event from "../components/events/event";

import INFO from "../data/user";
import SEO from "../data/seo";

import { getEvents } from "../api/client";

import "./styles/events.css";

const Events = () => {  
	const [isLoadingEvent, setIsLoadingEvent] = useState(true);  
	const [events, setEvents] = useState(null);  
	const [pastEvents, setPastEvents] = useState([]);  
	const [futureEvents, setFutureEvents] = useState([]);  
	
	const fetchEventContent = async () => {  
	  setIsLoadingEvent(true);  
	  try {  
		const res = await getEvents();  
		const sortedEvents = res.sort((a, b) => new Date(b.date) - new Date(a.date));  
		setEvents(sortedEvents);  
	
		const today = new Date();  
		const pastItems = [];  
		const futureItems = [];  
	
		for (const event of sortedEvents) {  
		  const eventDate = new Date(event.date);  
		  if (eventDate <= today) {  
			pastItems.push(event);  
		  } else {  
			futureItems.push(event);  
		  }  
		}  
	
		const sortedPastEvents = pastItems.sort((a, b) => new Date(b.date) - new Date(a.date));  
		const sortedFutureEvents = futureItems.sort((a, b) => new Date(a.date) - new Date(b.date));  
	
		setPastEvents(sortedPastEvents);  
		setFutureEvents(sortedFutureEvents);  
	
		setIsLoadingEvent(false);  
	  } catch (error) {  
		setIsLoadingEvent(false);  
		console.error('Error fetching events:', error);  
	  }  
	};  
	
	useEffect(() => {  
	  fetchEventContent();  
	}, []);  
	
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "events");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Events | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="events" />
				<div className="content-wrapper">
					<div className="events-logo-container">
						<div className="events-logo">
							<Logo width={46} />
						</div>
					</div>

					<div className="events-main-container">
						<div className="title events-title">
							{INFO.events.title}
						</div>

						<div className="subtitle events-subtitle">
							{INFO.events.description}
						</div>

						<div className="events-container">  
        <div className="events-wrapper">  
          <div>  
            <h2>今後のイベント</h2>  
            {isLoadingEvent ? (  
              <div>loading event...</div>  
            ) : (  
              futureEvents.map((event, index) => (  
                <div className="homepage-event" key={index}>  
                  <Event  
                    title={event.title}  
                    description={event.description}  
                    date={event.date}  
                    thumbnailUrl={event.thumbnailImagePath}  
                  />  
                </div>  
              ))  
            )}  
          </div>  
          <div>  
            <h2>過去のイベント</h2>  
            {isLoadingEvent ? (  
              <div>loading event...</div>  
            ) : (  
              pastEvents.map((event, index) => (  
                <div className="homepage-event" key={index}>  
                  <Event  
                    title={event.title}  
                    description={event.description}  
                    date={event.date}  
                    thumbnailUrl={event.thumbnailImagePath}  
                  />  
                </div>  
              ))  
            )}  
          </div>  
		  </div> 
		  </div> 
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Events;
