import React, {useState} from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import YouTube from "react-youtube";

import "./styles/works.css";

const mediaTypeString = (mediaTypeNumber) => {
	if(mediaTypeNumber == 0) return "image";
	if(mediaTypeNumber == 1) return "youtube";
	return "others";
}
const formatDateString = (inputDate) =>  {  
	const date = new Date(inputDate);  
	const year = date.getFullYear();  
	const month = (date.getMonth() + 1).toString().padStart(2, '0');  
	const day = date.getDate().toString().padStart(2, '0');  
	return `${year}/${month}/${day}`;  
}  

const EmbeddedMovie = ({src}) => {
	const videoId = new URL(src).searchParams.get('v');
	const height = "300px";
	const opts = {
		height: height,
		width:"100%",
	}
	const divStyle = {
		height: height
	};
	return (
		<div style={divStyle}>
			<YouTube videoId={videoId} opts={opts} />
		</div>
	) 
}

const ResizedImage = ({src}) => {
	const height = "300px";
	const divStyle = {
		height: height
	};
	const style = {
		height: height
	}
	return (
		<div style={divStyle}>
		<img
			src={src}
			style={style}
			/>
		</div>
	) 
}

const Work = ({ title, description, thumbnailMedia, key, medias, date }) => {    
	const [isMediasOpen, setIsMediasOpen] = useState(false);
	const openStateText = "画像を閉じる";
	const closeStateText = "他の画像を見る";
	function toggleMenu(){
		setIsMediasOpen(!isMediasOpen);
	}
	return (    
	  <div className="work-container" key={key}>    
		<div className="work-title">{title}</div>   
		<div className="work-date">{formatDateString(date)}作成</div>
		<div className="work-description">{description}</div>
		{mediaTypeString(thumbnailMedia.mediaType) == "image" &&
			<ResizedImage src={thumbnailMedia.path} />	
		}
		{mediaTypeString(thumbnailMedia.mediaType) == "youtube" &&
			<EmbeddedMovie src={thumbnailMedia.path} />	
		}
		{medias?.length > 0 &&
			<Link>
				<div className="work-link" onClick={toggleMenu}>
					<div className="work-link-icon">
						<FontAwesomeIcon icon={faLink} />
					</div>

					<div className="work-link-text">
						{isMediasOpen
							? openStateText
						: closeStateText}

					</div>
				</div>
			</Link>
		}
		{isMediasOpen && 
			medias.map((media, index) => {
				if(mediaTypeString(media.mediaType) === "image"){
					return (
						<div key={index}>
							<ResizedImage src={media.path} />
						</div>									
					)
				}
				if(mediaTypeString(media.mediaType) === "youtube"){
					return (
						<div key={index}>
							<EmbeddedMovie src={media.path}/>
						</div>
					)
				}								
			})
		}
	  </div>    
	);    
  };    
	  
  export default Work;  