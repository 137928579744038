export class GetMediaContentResponse{
    mediaContents;
}

class MediaContentModel {
    id;
    mediaContentType;
    title;
    description;
    thumbnailMedia;
    medias;
}

class MediaModel{
    mediaType;
    url;
    order;
}
