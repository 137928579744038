import React from "react";

import Menu from "./menu";
import INFO from "../../data/user";

import "./styles/allMenus.css";

const AllProjects = () => {
	return (
		<div className="all-menus-container">
			{INFO.menus.map((menu, index) => (
				<div className="all-menus-menu" key={index}>
					<Menu
						logo={menu.logo}
						title={menu.title}
						description={menu.description}
						linkText={menu.linkText}
						link={menu.link}
					/>
				</div>
			))}
		</div>
	);
};

export default AllProjects;