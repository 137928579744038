import axios, { AxiosError, AxiosResponse } from "axios";
import { GetMediaContentResponse } from "../models/GetMediaContentResponse";
import { GetArticleContentResponse } from "../models/GetArticleContentResponse";

axios.defaults.headers.get['Access-Control-Allow-Origin'] = "*";
// let endpoint = process.env.REACT_APP_API_ENDPOINT;
let endpoint = "https://func-yumeno-portfolio.azurewebsites.net/";
console.log(`connect to ${endpoint}`);
const client = axios.create({
  baseURL: `${endpoint}api/`
})

export const getWorks = async () => {
  try {  
    const response = await client.get("getWorks");  
    const { data, status } = response;  
    console.log("Data", data);
    return data;
  } catch (e) {  
    if(e instanceof Error){
      console.log(e.message);  
    }
    return new GetMediaContentResponse();  
  }  
}

export const getEvents = async () => {
  try {  
    const response = await client.get("GetEvents");  
    const { data, status } = response;  
    console.log("Data", data);
    return data;
  } catch (e) {  
    if(e instanceof Error){
      console.log(e.message);  
    }
    return new GetMediaContentResponse();  
  }  
}

export const getMediaContent = async () => {  
    try {  
      const response = await client.get("getMediaContent");  
      const { data, status } = response;  
      console.log("Data", data);
      return data;
    } catch (e) {  
      if(e instanceof Error){
        console.log(e.message);  
      }
      return new GetMediaContentResponse();  
    }  
};

export const getArticleContent = async () => {
  try {  
    const response = await client.get("getArticleContent");  
    const { data, status } = response;  
    console.log("Data", data);
    return data;
  } catch (e) {  
    if(e instanceof Error){
      console.log(e.message);  
    }
    return new GetArticleContentResponse();  
  }  

}
