import React, {useState, useEffect} from "react";
import Work from "./works";

import { getWorks } from "../../api/client";
import "./styles/allWorks.css";
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';  
import { MediaContentType } from "../../constants/mediaContentType";


const AllWorks = () => {
    const [isLoading, setIsLoading] = useState(true);
	const [works, setWorks] = useState([]);
	
	const displayWork = (mediaContents) => {
		return (
			<div className="all-works-work">
				{mediaContents.map((media, index) => {
				return (
					<Work  
						key={index}  
						title={media.title}  
						date={media.date}
						description={media.content}  
						thumbnailMedia={media.thumbnailMedia}
						medias={media.medias}  
					/>  
					)
				})}
			</div>
		)
	}

    const fetchMediaContent = async () => {
        setIsLoading(true);
        await getWorks()
        .then((res) => {
			res = res.sort((a, b) => new Date(b.date) - new Date(a.date));  
			setWorks(res);
            setIsLoading(false);
		})
        .catch(() => {
            setIsLoading(false);
        })
    };

    useEffect(() => {
        fetchMediaContent();
    }, []);

	return (
		<div className="all-works-container">
			<div className="all-works-work">
			{isLoading? <div>loading...</div> : displayWork(works)}
			</div>

		</div>
	);
};

export default AllWorks;
