import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import "./style/event.css";
const ResizedImage = ({src}) => {
	const height = "150px";
	const divStyle = {
		height: height
	};
	const style = {
		height: height
	}
	return (
		<div style={divStyle}>
		<img
			src={src}
			style={style}
			/>
		</div>
	) 
}

const Event = (props) => {
	const {title, description, date, thumbnailUrl } = props;
	console.log(thumbnailUrl);

	return (
		<React.Fragment>
			<div className="event event-container">
				<div className="event-right-side">
					<div className="event-title">{title}</div>
					<div className="event-date">{new Date(date).toDateString()}</div>
					<div className="event-description">{description}</div>
					{thumbnailUrl != "not found" ? <ResizedImage src={thumbnailUrl}/> : ""}
				</div>
			</div>
		</React.Fragment>
	);
};

export default Event;
